import { cdnBase } from '../../const';

export default {
  en_us: {
    langKeys: {
      en_us: 'English (US)',
      mx_mx: 'Mexico',
      nl_nl: "Netherlands"
    },
    interstitial: {
      heading: 'Leaving this Site',
      copy: 'By clicking "continue" you\'ll be leaving this web site and landing on a retail site. Remember, they have different terms and privacy policies. Hope we see you again soon! KIDS, get your parent\'s permission before visiting other sites and NEVER share any personal info about yourself — including your full name, address and phone number and let your parent or guardian handle any transaction.',
      continue: 'Continue',
      cancel: 'Cancel'
    },
    brand: 'Tech Deck',
    applinks: {
      apple: 'LINK TO APPLE APP',
      google: 'LINK TO GOOGLE APP'
    },
    chooseRegion: 'Choose Language',
    loading: 'Loading',
    select: 'Select',
    clearAll: 'Clear All',
    filters: 'Filters',
    details: 'Details',
    warnings: {
      enableIframes: 'You Must Enable iFrames to view this video'
    },
    helmet: {
      siteTitle: ' | Tech Deck '
    },
    navigation: {
      mainmenu: [
        {
          type: 'internal',
          href: '/toys',
          display: 'Products'
        },
        // It has been requested for this menu item to be removed until an update for the collections page is provided at a later date
        // {
        //   type: 'internal',
        //   href: '/collection',
        //   display: 'Collection'
        // },
        {
          type: 'external',
          href: 'https://www.youtube.com/user/TechDeckVideos',
          display: 'Videos'
        },
        {
          type: 'internal',
          href: '/downloads',
          display: 'Downloads'
        },
        //{
        //  type: 'internal',
        //  href: '/events',
        //  display: 'Events'
        //},
        {
          type: 'internal_hash',
          href: '/#social',
          display: 'Social'
        },
        {
          type: 'internal_hash',
          href: '/#wheretobuy',
          display: 'Where to Buy'
        },
        {
          type: 'external',
          href: 'https://spinmastersupport.helpshift.com/a/tech-deck/?p=web',
          display: 'Support'
        }
      ],
      logo: "https://techdeck2019.s3.amazonaws.com/images/logos/td_logo_updated.png"
    },
    footer: {
      brand: 'Spin Master',
      contactUs: "Contact Us",
      privacy: 'Privacy Policy',
      terms: 'Terms of Service',
      support: 'Support',
      copy: 'Spin Master Ltd. All Rights Reserved.',
      contactLink: 'https://www.spinmaster.com/contact-us.php?userLoc=us&display=customer_service_info',
      privacyLink: 'https://www.spinmaster.com/privacy-policy.php?userLoc=us',
      termsLink: 'https://www.spinmaster.com/terms-of-service.php?userLoc=us',
      supportLink: 'https://www.spinmaster.com/support.php?userLoc=us'
    },
    home: {
      title: 'Home',
      whereToBuy: 'Where To Buy',
      sections: {
        collection: {
          heading: "You have the magic to make Awesome Bloss’ems grow!",
          subheading: ["Collection", "Series One"],
          button: "View Full Collection"
        },
        smallbutton: [
          {
            class: 'academy',
            bg: {
              type: 'vid',
              src: `${cdnBase}videos/F21_June_TechDeck_Downloads_720x480.mp4`,
              mobilesrc: `${cdnBase}images/banners/holder.png`
            },
            heading: 'Tech Deck',
            headingbig: '2022 Releases',
            copy: 'Check out what’s dropping in 2022. Can you collect them all?',
            link: '/en_us/downloads'
          },
          {
            class: 'collection',
            bg: {
              type: 'vid',
              src: `${cdnBase}videos/hallof.mp4`,
              mobilesrc: `${cdnBase}images/banners/holder.png`
            },
            heading: 'Build Your',
            headingbig: 'Collection',
            copy: 'Build your Tech Deck collection! Start small, go big!',
            link: '/en_us/toys'
          },
          {
            class: 'community',
            bg: {
              type: 'img',
              src: `${cdnBase}images/banners/community.jpg`,
              mobilesrc: `${cdnBase}images/banners/community.jpg`
            },
            heading: 'Tech Deck',
            headingbig: 'Community',
            copy: 'Check out the incredible Tech Deck Community',
            link: '#social'
          }
        ],
        bigbutton: [
          {
            bg: {
              type: 'vid',
              src: `${cdnBase}techupdates/Assets+for+Dev+2/+LilJah-Makingof-VIdeo-Loop.mp4`,
              mobilesrc: `${cdnBase}techupdates/Assets+for+Dev+2/LilJa-VideoLoadingFrame.jpg`
            },
            heading: 'Last',
            headingbig: 'Vidz',
            copy: '#Watch the making of `Lil Jah` Stop Animation Commercial for the release of the new Nyjah Skate Park Set',
            link: 'https://techdeck2019.s3.amazonaws.com/techupdates/Assets+for+Dev+2/+LilJah-Makingof-VIdeo-Loop.mp4',
            link2: 'https://www.youtube.com/user/TechDeckVideos/videos',
            class: 'vidz'
          },
          {
            bg: {
              type: 'vid',
              src: `${cdnBase}techupdates/Assets+for+Dev+2/X-Connect-Video-Loop.mp4`,
              mobilesrc: `${cdnBase}techupdates/Assets+for+Dev+2/x-Connect-VideoLoadingFrame.jpg`
            },
            heading: 'Last',
            headingbig: 'Vidz',
            copy: '#Tech Deck X Connect lets you build the super park of your dreams!',
            link: 'https://techdeck2019.s3.amazonaws.com/techupdates/Assets+for+Dev+2/X-Connect-Video-Loop.mp4',
            link2: 'https://www.youtube.com/user/TechDeckVideos/videos',
            class: 'vidz'
          }
        ],
        social: {
          header: "Social",
          copy: "Check out behind the scenes of our past events, sneak peak of new board graphics and special swag exclusive to Tech Deck fans.",
          img: [
            {
              class: "instagram",
              url: "https://www.instagram.com/techdeck/",
              img: `${cdnBase}images/icons/instagram-spinblue.svg`
            },
            {
              class: "youtube",
              url: "https://www.youtube.com/user/TechDeckVideos",
              img: `${cdnBase}images/icons/youtube-spinblue.svg`
            },
            {
              class: "facebook",
              url: "https://www.facebook.com/TechDeck/",
              img: `${cdnBase}images/icons/facebook-spinblue.svg`
            },
            {
              class: "tiktok",
              url: "https://www.tiktok.com/@techdeck",
              img: `${cdnBase}images/icons/tiktok-spinblue.svg`
            }
          ]
        }
      },

      slides: [
        {
          heading: 'Shred like ',
          subheading: 'the Pros!',
          cta: 'see the newest ramp',
          link: '/en_us/toys/778988429952',
          img: `${cdnBase}93c4d973-56db-4027-8a50-cc7095f4da7c.png`,
          img2x: `${cdnBase}93c4d973-56db-4027-8a50-cc7095f4da7c.png`
        },
        {
          heading: 'Ramp Up your',
          subheading: 'trick game!',
          cta: 'see the newest ramp',
          link: '/en_us/toys/778988409503',
          img: `${cdnBase}techupdates/Assets+for+Dev+2/S22_TechDeck_HeroBanner-Ramps.png`,
          img2x: `${cdnBase}d36ba25d-c62a-4f7b-8653-295431e09d91.png`
        }
      ],
      retailers_header: 'where to buy',
      retailers: [
        {
          name: 'Amazon',
          href: 'https://www.amazon.com/s/ref=nb_sb_noss_2?url=search-alias%3Daps&field-keywords=tech+deck',
          img: `${cdnBase}images/retailers/amazon.png`
        },
        {
          name: 'Target',
          href: 'http://www.target.com/s?category=0%7CAll%7Cmatchallpartial%7Call+categories&searchTerm=tech%20deck',
          img: `${cdnBase}images/retailers/target.png`
        },
        {
          name: 'Walmart',
          href: 'http://www.walmart.com/search/?cat_id=&query=tech+deck',
          img: `${cdnBase}images/retailers/walmart.png`
        },
        {
          name: 'Kmart',
          href: 'http://www.kmart.com/search=tech%20deck',
          img: `${cdnBase}images/retailers/kmart.png`
        }
      ]
    },
    collection: {
      title: 'Tech Deck Collection',
      nextPage: 'Next Page',
      prevPage: 'Prev Page',
      all: 'All',
      wantIt: 'Want it',
      haveIt: 'Have it',
      flipIt: 'Facts',
      brand: 'Brand',
      year: 'Year',
      skater: 'Skater',
      series: 'Series',
      showing: 'Showing',
      filters: [
        'brand',
        'year',
        'skater',
        'series'
      ]
    },
    support: {
      title: 'Support',
      subTitle: 'Support'
    },
    toys: {
      title: 'Toys',
      subTitle: 'Toys',
      learn: 'learn',
      more: 'more'
    },
    toy: {
      title: 'Toy',
      subTitle: 'Toy',
      backToToys: 'Back to Toys',
      features: 'Features',
      image: 'Image',
      contents: 'Contents',
      whereToBuy: 'Where to Buy',
      instructions: 'Instructions',
      videoInstructions: 'Video Instructions',
      buyNow: 'Buy Now',
      retailers: 'Retailers'
    },
    downloads: {
      title: 'Downloads',
      dldownload: 'Download',
      bestResults: 'For best results, print your Gifeez disks on thicker paper',
      rightDisks: `${cdnBase}downloads/right_disk_compress.png`,
      rightDisks_Alt: 'smiley and car disks',
      leftDisks: `${cdnBase}downloads/left_disk2_compress.png`,
      leftDisks_Alt: 'robot and eggs disks',
      catList: ['all', 'animation', 'art'],
      animationImg: `${cdnBase}downloads/animation_decal.png`,
      artImg: `${cdnBase}downloads/art_decal.png`,
      diskDownloadsList: [
        {
          title: 'Drop 1',
          poster: `${cdnBase}images/drop_thumbs/S22_TechDeck_Drop_Thumbs_0000_2022+Drop+1.jpg`,
          downloadPDF: `${cdnBase}downloads/2022+Drop+1.pdf`,
          categories: ['all', 'art']
        },
        {
          title: 'Drop 2',
          poster: `${cdnBase}images/drop_thumbs/S22_TechDeck_Drop_Thumbs_0001_2022+Drop+2.jpg`,
          downloadPDF: `${cdnBase}downloads/2022+Drop+2.pdf`,
          categories: ['all', 'animation'],
        },
        {
          title: 'Drop 3',
          poster: `${cdnBase}images/drop_thumbs/S22_TechDeck_Drop_Thumbs_0002_2022+Drop+3.jpg`,
          downloadPDF: `${cdnBase}downloads/2022+Drop+3.pdf`,
          categories: ['all', 'animation'],
        },
        {
          title: 'Drop 4',
          poster: `${cdnBase}images/drop_thumbs/S22_TechDeck_Drop_Thumbs_0003_2022+Drop+4.jpg`,
          downloadPDF: `${cdnBase}downloads/2022+Drop+4.pdf`,
          categories: ['all', 'animation'],
        }
      ]
    },
    videos: {
      title: 'Videos',
      subTitle: 'Videos',
      playVideo: 'Play Video',
      playButton_Alt: 'play button',
      defaultVideoIndex: 0,
      categories: 'Categories',
      catList: ['all', 'tips and tricks', 'skater vs', 'commercials', 'hall of fame', 'events'],
      videoList: [
        {
          title: 'TRICK TUESDAY - VARIAL HEELFLIP',
          video: 'Y7xzS0DtQu4',
          type: 'youtube',
          categories: ['all', 'tips and tricks']
        },
        {
          title: 'TRICK TUESDAY - BACKSIDE FLIP',
          video: 'WIRLEQs8FtM',
          type: 'youtube',
          categories: ['all', 'tips and tricks']
        },
        {
          title: 'TRICK TUESDAY - BIGGER SPIN FLIP',
          video: 'wBXhz-A9oaA',
          type: 'youtube',
          categories: ['all', 'tips and tricks']
        },
        {
          title: 'TRICK TUESDAY - 540 FLIP',
          video: 'zIRPkCrRdqk',
          type: 'youtube',
          categories: ['all', 'tips and tricks']
        },
        {
          title: 'TRICK TUESDAY - 360 DOUBLE FLIP',
          video: 'YTtRO2JO4zk',
          type: 'youtube',
          categories: ['all', 'tips and tricks']
        },
        {
          title: 'TRICK TUESDAY - 360 DOUBLE FLIP',
          video: 'YTtRO2JO4zk',
          type: 'youtube',
          categories: ['all', 'tips and tricks']
        },
        {
          title: 'TRICK TUESDAY - 360 DOUBLE FLIP',
          video: 'YTtRO2JO4zk',
          type: 'youtube',
          categories: ['all', 'tips and tricks']
        },
        {
          title: 'TRICK TUESDAY - 360 DOUBLE FLIP',
          video: 'YTtRO2JO4zk',
          type: 'youtube',
          categories: ['all', 'tips and tricks']
        },
        {
          title: 'TRICK TUESDAY - 360 DOUBLE FLIP',
          video: 'YTtRO2JO4zk',
          type: 'youtube',
          categories: ['all', 'tips and tricks']
        },
        {
          title: 'TRICK TUESDAY - NOLLIE 360 FLIP',
          video: '4-MXtHedIXU',
          type: 'youtube',
          categories: ['all', 'tips and tricks']
        },
        {
          title: 'TECK DECK - HOW TO OLLIE',
          video: 'Y7xzS0DtQu4',
          type: 'youtube',
          categories: ['all', 'tips and tricks']
        },
        {
          title: 'MASTER TECH DECK FINGERBOARDER',
          video: 'EmrYO3Sj0M0',
          type: 'youtube',
          categories: ['all', 'tips and tricks']
        },
        {
          title: 'TECH DECK TRICK VIDEO #1: SHOVE IT',
          video: 'NPkKwpsGUc',
          type: 'youtube',
          categories: ['all', 'tips and tricks']
        },
        {
          title: 'TECH DECK TRICK VIDEO #2: OLLIE / NOLLIE',
          video: 'lgeO_y4RGIE',
          type: 'youtube',
          categories: ['all', 'tips and tricks']
        },
        {
          title: 'TECH DECK TRICK VIDEO #3: SLIDES',
          video: 'DLBqbuUPiuo',
          type: 'youtube',
          categories: ['all', 'tips and tricks']
        },
        {
          title: 'TECH DECK TRICK VIDEO #4: GRINDS',
          video: 'aMQNMifd7AE',
          type: 'youtube',
          categories: ['all', 'tips and tricks']
        },
        {
          title: 'TECH DECK TRICK VIDEO #5: FLIPS',
          video: 'rZUzb302KW8',
          type: 'youtube',
          categories: ['all', 'tips and tricks']
        },
        {
          title: 'TECH DECK TRICK VIDEO #6: RAMP TRICKS',
          video: '_UIalVhh_HQ',
          type: 'youtube',
          categories: ['all', 'tips and tricks']
        },
        {
          title: 'TECH DECK TRICK VIDEO #7: PUTTING IT ALL TOGETHER',
          video: 'Wbb7GWpt_MU',
          type: 'youtube',
          categories: ['all', 'tips and tricks']
        },
        {
          title: 'TECH DECK TUTORIALS: GETTING STARTED',
          video: 'Rp1VRRZGYi0',
          type: 'youtube',
          categories: ['all', 'tips and tricks']
        },
        {
          title: 'TECH DECK TUTORIALS: INTERMEDIATE STREET TRICKS',
          video: 'exi1QzBaJR4',
          type: 'youtube',
          categories: ['all', 'tips and tricks']
        },
        {
          title: 'TECH DECK TUTORIALS: BASIC STREET TRICKS',
          video: 'XTm0JV3RrX8',
          type: 'youtube',
          categories: ['all', 'tips and tricks']
        },
        {
          title: 'TECH DECK TUTORIALS: PLAYING S-K-A-T-E',
          video: 'oLO8VOI2a64',
          type: 'youtube',
          categories: ['all', 'tips and tricks']
        },
        {
          title: 'TECH DECK TUTORIALS: INTERMEDIATE VERT TRICKS',
          video: 'HblCzK70ZSw',
          type: 'youtube',
          categories: ['all', 'tips and tricks']
        },
        {
          title: 'TECH DECK TUTORIALS: ADVANCED VERT TRICKS',
          video: '1fJlTyUS7mM',
          type: 'youtube',
          categories: ['all', 'tips and tricks']
        },
        {
          title: 'TECH DECK TRICK TAPE - FLIP TRICKS',
          video: 'B7DVCA-T1TQ',
          type: 'youtube',
          categories: ['all', 'tips and tricks']
        },
        {
          title: 'TECH DECK TRICK TAPE - GETTING STARTED',
          video: 'zLjdg-5sCyA',
          type: 'youtube',
          categories: ['all', 'tips and tricks']
        },
        {
          title: 'Skater Vs… Cheeseburger',
          video: 'eJ1kCjQHPTg',
          type: 'youtube',
          categories: ['all', 'skate vs']
        },
        {
          title: 'Skater Vs... Human Skeeball',
          video: 'k80JZ_I_uRs',
          type: 'youtube',
          categories: ['all', 'skater vs']
        },
        {
          title: 'SKATER VS... PIÑATA',
          video: 'Z0sF96aj-So',
          type: 'youtube',
          categories: ['all', 'skater vs']
        },
        {
          title: 'SKATER VS... PIÑATA',
          video: 'Z0sF96aj-So',
          type: 'youtube',
          categories: ['all', 'skater vs']
        },
        {
          title: 'TECH DECK - START SMALL GO BIG!',
          video: 'AUdmc3swvcE',
          type: 'youtube',
          categories: ['all', 'commercials']
        },
        {
          title: '#SKATEORSCOOT SERIES - SKATE CITY',
          video: 'sZ8Qlx72dt',
          type: 'youtube',
          categories: ['all', 'commercials']
        },
        {
          title: 'TECH DECK DUDES VANFORMER',
          video: 'Srft3KKXdis',
          type: 'youtube',
          categories: ['all', 'commercials']
        },
        {
          title: 'TECH DECK COMMERCIAL: FALL 2010',
          video: 'E-sgk4P95jA',
          type: 'youtube',
          categories: ['all', 'commercials']
        },
        {
          title: 'TECH DECK PAUL RODRIGUEZ SKATELAB COMMERCIAL',
          video: 'usXjTSVq7UY',
          type: 'youtube',
          categories: ['all', 'commercials']
        },
        {
          title: `TECH DECK'S 2011 SKATE SHOP COLLAB BOARDS`,
          video: 'kVfWcA4HEyM',
          type: 'youtube',
          categories: ['all', 'commercials']
        },
        {
          title: `TECH DECK SKATE AND GO TV SPOT`,
          video: 'vDPp1xN94YI',
          type: 'youtube',
          categories: ['all', 'commercials']
        },
        {
          title: `TECH DECK TONY HAWK BIG RAMPS`,
          video: 'rAj9VYXVs1g',
          type: 'youtube',
          categories: ['all', 'commercials']
        },
        {
          title: `BAG OF TRICKS`,
          video: 'ybK00UKz1DE',
          type: 'youtube',
          categories: ['all', 'commercials']
        },
        {
          title: `TECH DECK TRICK TAPE - GETTING STARTED`,
          video: 'zLjdg-5sCyA',
          type: 'youtube',
          categories: ['all', 'commercials']
        },
        {
          title: `TECH DECK LONGBOARDS`,
          video: 'bUfFVSJstQQ',
          type: 'youtube',
          categories: ['all', 'commercials']
        },
        {
          title: `FINGERBOARDER VS SKATER`,
          video: 'DIfn_QSoF5E',
          type: 'youtube',
          categories: ['all', 'hall of fame']
        },
        {
          title: `BROTHER & SISTER`,
          video: 'TX1aMdIqx2o',
          type: 'youtube',
          categories: ['all', 'hall of fame']
        },
        {
          title: `FINGERBOARD SPIRIT`,
          video: '2prsTK1X4Iw',
          type: 'youtube',
          categories: ['all', 'hall of fame']
        },
        {
          title: `MASTER SKATER`,
          video: 'NF26LZHyf08',
          type: 'youtube',
          categories: ['all', 'hall of fame']
        },
        {
          title: `NEVER STOP SKATING`,
          video: 'zQyijf7tdUY',
          type: 'youtube',
          categories: ['all', 'hall of fame']
        },
        {
          title: `POPS`,
          video: 'fobZUTlbTEA',
          type: 'youtube',
          categories: ['all', 'hall of fame']
        },
        {
          title: `TECH DECK AT DEW TOUR`,
          video: 'u-14TXK1LjM',
          type: 'youtube',
          categories: ['all', 'events']
        }
      ]
    },
    events: {
      title: 'events',
      copy: `Its been a crazy year but things are looking up! Check out some of the awesome events we’ll be at this year. We will announce more details soon so keep checking back!
      `,
      upcomingevents: [

      ],
      upcomingheader: 'Upcoming Events',
      ctaimage: 'images',
      ctalearnmore: 'learn more',
      pastheader: 'Past Events',
      pastevents: [
        {
          thumb: `${cdnBase}images/events/dew_tour2021.png`,
          title: 'Dew Tour Details',
          copy: `Dew Tour is an innovative contest series and content platform that brings together the world’s best skateboarders. The event includes World Skate sanctioned men’s and women’s skateboarding Street and Park competitions, serving as the only U.S.-based global Olympic skateboard qualifying events for 2021.`,
          images: [],
          ctalink: 'https://www.dewtour.com/skate/',
          date: {
            month: 'May',
            day: '20th - 23rd',
            time: 'All Day',
            location: 'Des Moines, Iowa',
            year: 2021
          }
        },
        // {
        //   thumb: `${cdnBase}images/events/exposure.jpg`,
        //   title: 'Exposure Skate Event',
        //   copy: 'Exposure creates opportunities for female skateboarders and raises money for survivors of domestic violence.',
        //   images: [],
        //   ctalink: 'http://exposureskate.org/',
        //   date: {
        //     month: 'November',
        //     day: '5th',
        //     time: 'All Day',
        //     year: 2017
        //   }
        // },

        // {
        //   thumb: `${cdnBase}images/events/sls_2.jpg`,
        //   title: 'SLS Nike Super Crown World Tour Championships',
        //   copy: 'Come visit us at the Tech Deck booth and show us your best Fingerboarding skills!',
        //   images: [],
        //   ctalink: 'http://streetleague.com/?event=sls-nike-sb-super-crown-world-championship',
        //   date: {
        //     month: 'October',
        //     day: '2nd',
        //     time: '4:00 PM',
        //     year: 2016
        //   }
        // },
        // {
        //   thumb: `${cdnBase}images/events/sls_1.jpg`,
        //   title: 'SLS Nike SB World Tour',
        //   copy: 'Come visit us at the Tech Deck booth and show us your best Fingerboarding skills!',
        //   images: [],
        //   ctalink: 'http://streetleague.com/?event=sls-nike-sb-world-tour-new-jersey',
        //   date: {
        //     month: 'August',
        //     day: '28th',
        //     time: '12:15 PM',
        //     year: 2016
        //   }
        // },
        // {
        //   thumb: `${cdnBase}images/events/dew_tour.jpg`,
        //   title: 'Dew Tour',
        //   copy: 'Dew Tour is an innovative contest series and content platform that brings together the world’s best skateboarders.',
        //   images: [],
        //   ctalink: 'http://www.dewtour.com/skate/',
        //   date: {
        //     month: 'July',
        //     day: '22-24th',
        //     time: 'All Day',
        //     year: 2016
        //   }
        // },
        // {
        //   thumb: `${cdnBase}images/events/clash-at-clairmont.jpg`,
        //   title: 'Clash at Clairmont',
        //   copy: 'The event, a celebration of the newly acquired ramp and park renovations was an opportunity to create a fundraiser that ...',
        //   images: [],
        //   ctalink: 'http://www.clashatclairemont.com/Clash7/',
        //   date: {
        //     month: 'June',
        //     day: '25th',
        //     time: '11am - 6:30pm',
        //     year: 2016
        //   }
        // },
        // {
        //   thumb: `${cdnBase}images/events/go-skateboarding-day.jpg`,
        //   title: 'GO Skateboarding Day',
        //   copy: 'On June 21 skateboarders around the globe will celebrate the pure exhilaration, creativity, and spirit of one of the mos...',
        //   images: [],
        //   ctalink: 'http://theiasc.org/go-skateboarding-day/',
        //   date: {
        //     month: 'June',
        //     day: '21st',
        //     time: 'All Day',
        //     year: 2016
        //   }
        // }
      ]
    },
    nomatch: {
      title: 'Page Not Found'
    },
    privacy: {
      title: 'Privacy Policy',
      subTitle: 'Privacy Policy',
      link: 'https://www.spinmaster.com/en-US/legal/privacy-policy'
    },
    terms: {
      title: 'Terms of Service',
      subTitle: 'Terms of Service',
      link: 'https://www.spinmaster.com/en-US/legal/terms-of-use'
    }
  }
}