export const cdnBase = 'https://s3.amazonaws.com/techdeck2019/';
// export const atgBase = process.env.NODE_ENV === 'development' ? 'https://salsifystaging.spinmasterstudios.com/' : 'https://prodfeed.spinmasterstudios.com/';
export const atgBase = 'https://salsifystaging.spinmasterstudios.com/';
//export const atgBase = 'https://prodfeed.spinmasterstudios.com/';

export const atgBrand = 'cat_tech_deck';
export const salsifyBrand = 'cat_tech_deck';

// Please see below for sample dev & prod feeds
// *** make sure to update this section if things change
// DEV: https://salsifystaging.spinmasterstudios.com/rest/service/sm/us/catalog/ProductList?root=cat_tech_deck
// PROD: https://prodfeed.spinmasterstudios.com/rest/service/sm/us/catalog/ProductList?root=cat_tech_deck

export const atgProductDetailRoot = 'https://shop.spinmaster.com/product/';
export const UA = 'UA-XXXXXXXXXX-X';
export const collectionServer = 'http://lampclusterlarge.spinmasterstudios.com:1337/collection/techdeck/';

// If you want to eject from react create app and not have to define these twice you could try this:
// https://github.com/nordnet/sass-variable-loader
// These are the same breaks as in _vars.sass
export const breakXSmall = 400;

export const breakSmall = 720;

export const breakTablet = 840;

export const breakLaptop = 1300;

export const breakXLaptop = 1600;

export const breakXLarge = 1600;

// To add a new locale, you must add it on this list
export const siteMapByLocale = {
  'en_us': 'us',
  'mx_mx': 'mx',
  "nl_nl": 'nl'
};


// set to true if you want locale to display component
// export const localeBanlist={

// 	appSectionLocale: {
// 		en_us: true
// 	}
	
// }

// If you wish to review locales but not have them appear in the menu, add them here.
export const blockedLocales = [];
